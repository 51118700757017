// Filters list
//
// A vertical list of filters.
.filter-list {
  list-style-type: none;

  &.small .filter-item {
    // stylelint-disable-next-line primer/spacing
    padding: 6px 12px;
    font-size: $font-size-small;
  }

  &.pjax-active .filter-item {
    color: var(--color-text-secondary);
    background-color: transparent;

    &.pjax-active {
      color: var(--color-text-inverse);
      background-color: var(--color-bg-info-inverse);
    }
  }
}

.filter-item {
  position: relative;
  display: block;
  padding: $spacer-2 $spacer-3;
  margin-bottom: $spacer-1;
  overflow: hidden;
  font-size: $h5-size;
  color: var(--color-text-secondary);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border-radius: $border-radius;

  &:hover {
    text-decoration: none;
    background-color: var(--color-bg-tertiary);
  }

  &.selected,
  &[aria-selected=true],
  &[aria-current]:not([aria-current=false]) {
    color: var(--color-state-selected-primary-text);
    background-color: var(--color-state-selected-primary-bg);
  }

  .count {
    float: right;
    font-weight: $font-weight-bold;
  }

  .bar {
    position: absolute;
    top: 2px;
    right: 0;
    bottom: 2px;
    z-index: -1;
    display: inline-block;
    background-color: var(--color-filter-item-bar-bg);
  }
}
