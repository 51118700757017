// Custom select
//
// Apply `.select` to any `<select>` element for custom styles.
.form-select {
  display: inline-block;
  max-width: 100%;
  height: $size-5;
  padding-right: $spacer-4;
  background-color: var(--color-bg-primary);
  // SVG with fill: #586069 (--color-icon-secondary)
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0iIzU4NjA2OSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNC40MjcgOS40MjdsMy4zOTYgMy4zOTZhLjI1MS4yNTEgMCAwMC4zNTQgMGwzLjM5Ni0zLjM5NkEuMjUuMjUgMCAwMDExLjM5NiA5SDQuNjA0YS4yNS4yNSAwIDAwLS4xNzcuNDI3ek00LjQyMyA2LjQ3TDcuODIgMy4wNzJhLjI1LjI1IDAgMDEuMzU0IDBMMTEuNTcgNi40N2EuMjUuMjUgMCAwMS0uMTc3LjQyN0g0LjZhLjI1LjI1IDAgMDEtLjE3Ny0uNDI3eiIgLz48L3N2Zz4=");
  background-repeat: no-repeat;
  background-position: right 4px center;
  background-size: 16px;
  appearance: none;

  // Hides the default caret in IE11
  &::-ms-expand {
    opacity: 0;
  }

  &[multiple] {
    height: auto;
  }
}

@include color-mode(dark) {
  .form-select {
    // SVG with fill: #6e7681 (--color-icon-secondary)
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0iIzZlNzY4MSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNC40MjcgOS40MjdsMy4zOTYgMy4zOTZhLjI1MS4yNTEgMCAwMC4zNTQgMGwzLjM5Ni0zLjM5NkEuMjUuMjUgMCAwMDExLjM5NiA5SDQuNjA0YS4yNS4yNSAwIDAwLS4xNzcuNDI3ek00LjQyMyA2LjQ3TDcuODIgMy4wNzJhLjI1LjI1IDAgMDEuMzU0IDBMMTEuNTcgNi40N2EuMjUuMjUgMCAwMS0uMTc3LjQyN0g0LjZhLjI1LjI1IDAgMDEtLjE3Ny0uNDI3eiIgLz48L3N2Zz4=");
  }
}

.select-sm {
  height: $size-4;
  // stylelint-disable-next-line primer/spacing
  padding-top: 3px;
  // stylelint-disable-next-line primer/spacing
  padding-bottom: 3px;
  font-size: $font-size-small;

  &[multiple] {
    height: auto;
    min-height: 0;
  }
}
