// Layout utilities
// stylelint-disable block-opening-brace-space-before

// Responsive utilities to position content
// No utilities for sm and xl breakpoints
@each $breakpoint, $variant in $marketing-position-variants {
  @include breakpoint($breakpoint) {
    @each $scale, $size in $marketing-all-spacers {
      .top#{$variant}-#{$scale}     { top: $size !important; }
      .right#{$variant}-#{$scale}   { right: $size !important; }
      .bottom#{$variant}-#{$scale}  { bottom: $size !important; }
      .left#{$variant}-#{$scale}    { left: $size !important; }

      .top#{$variant}-n#{$scale}     { top: -$size !important; }
      .right#{$variant}-n#{$scale}   { right: -$size !important; }
      .bottom#{$variant}-n#{$scale}  { bottom: -$size !important; }
      .left#{$variant}-n#{$scale}    { left: -$size !important; }
    }
  }
}

// Negative offset columns
@each $breakpoint, $variant in $responsive-variants {
  @include breakpoint($breakpoint) {
    @for $offset from 1 through 7 {
      .offset#{$variant}-n#{$offset} { margin-left: -($offset / 12 * 100%); }
    }
  }
}

// Width and height utilities, especially needed when the
// dimensions of an image are set in HTML
.width-auto { width: auto !important; }
.height-auto { height: auto !important; }

// Make an object fill its parent
.object-fit-cover { object-fit: cover !important; }

// Handling z-index
.z-1 { z-index: 1 !important; }
.z-2 { z-index: 2 !important; }
.z-3 { z-index: 3 !important; }

// Negative z-index
.z-n1 { z-index: -1 !important; }
.z-n2 { z-index: -2 !important; }
