.btn-mktg {
  display: inline-block;
  padding: $spacer-3 $spacer-4;
  font-size: $h5-size;
  font-weight: $font-weight-semibold;
  color: var(--color-mktg-btn-text);
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--color-mktg-btn-bg);
  border: $border-width $border-style var(--color-mktg-btn-border);
  border-radius: $border-radius;
  transition-duration: $transition-time / 2;
  transition-property: background-color, border-color, box-shadow;
  appearance: none; // Corrects inability to style clickable `input` types in iOS.

  &:hover {
    text-decoration: none;
    background-color: var(--color-mktg-btn-hover-bg);
    border-color: var(--color-mktg-btn-hover-border);
  }

  &:focus {
    outline: 0;
    box-shadow: var(--color-mktg-btn-focus-shadow);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled=true] {
    color: var(--color-mktg-btn-disabled-text);
    pointer-events: none; // Disable hover styles
    cursor: default;
    background-color: var(--color-mktg-btn-disabled-bg);
    border-color: var(--color-mktg-btn-disabled-border);
  }
}

.btn-primary-mktg {
  color: var(--color-mktg-btn-primary-text);
  background-color: var(--color-mktg-btn-primary-bg);
  border-color: var(--color-mktg-btn-primary-border);

  &:hover {
    background-color: var(--color-mktg-btn-primary-hover-bg);
    border-color: var(--color-mktg-btn-primary-hover-border);
  }

  &:focus {
    box-shadow: var(--color-mktg-btn-primary-focus-shadow);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled=true] {
    color: var(--color-mktg-btn-primary-disabled-text);
    background-color: var(--color-mktg-btn-primary-disabled-bg);
    border-color: var(--color-mktg-btn-primary-disabled-border);
  }
}

.btn-outline-mktg {
  color: var(--color-mktg-btn-outline-text);
  background-color: var(--color-mktg-btn-outline-bg);
  border-color: var(--color-mktg-btn-outline-border);

  &:hover {
    color: var(--color-mktg-btn-outline-hover-text);
    background-color: var(--color-mktg-btn-outline-hover-bg);
    border-color: var(--color-mktg-btn-outline-hover-border);
  }

  &:focus {
    box-shadow: var(--color-mktg-btn-outline-focus-shadow);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled=true] {
    color: var(--color-mktg-btn-outline-disabled-text);
    background-color: var(--color-mktg-btn-outline-disabled-bg);
    border-color: var(--color-mktg-btn-outline-disabled-border);
  }
}

.btn-transparent {
  color: var(--color-mktg-btn-dark-text);
  background-color: var(--color-mktg-btn-dark-bg);
  border-color: var(--color-mktg-btn-dark-border);

  &:hover {
    color: var(--color-mktg-btn-dark-hover-text);
    background-color: var(--color-mktg-btn-dark-hover-bg);
    border-color: var(--color-mktg-btn-dark-hover-border);
  }

  &:focus {
    box-shadow: var(--color-mktg-btn-dark-focus-shadow);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled=true] {
    color: var(--color-mktg-btn-dark-disabled-text);
    background-color: var(--color-mktg-btn-dark-disabled-bg);
    border-color: var(--color-mktg-btn-dark-disabled-border);
  }
}

// Large

.btn-large-mktg {
  // stylelint-disable-next-line primer/spacing
  padding: 20px $spacer-5;
  font-size: $h4-size;
}
