// Needs refactoring
// stylelint-disable selector-max-type
.markdown-body {
  // Tables
  table {
    display: block;
    width: 100%; // keep for backwards compatibility
    width: max-content;
    max-width: 100%;
    overflow: auto;

    th {
      font-weight: $font-weight-bold;
    }

    th,
    td {
      // stylelint-disable-next-line primer/spacing
      padding: 6px 13px;
      border: $border-width $border-style var(--color-markdown-table-border);
    }

    tr {
      background-color: var(--color-bg-primary);
      border-top: $border-width $border-style var(--color-markdown-table-tr-border);

      &:nth-child(2n) {
        background-color: var(--color-bg-tertiary);
      }
    }

    img {
      background-color: transparent;
    }
  }
}
