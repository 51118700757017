// Side menu
//
// A menu on the side of a page, defaults to left side. e.g. github.com/about

.menu {
  margin-bottom: $spacer-3;
  list-style: none;
  background-color: var(--color-bg-primary);
  border: $border-width $border-style var(--color-border-primary);
  border-radius: $border-radius;
}

.menu-item {
  position: relative;
  display: block;
  padding: $spacer-2 $spacer-3;
  color: var(--color-text-primary);
  border-bottom: $border-width $border-style var(--color-border-secondary);

  &:first-child {
    border-top: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    &::before { border-top-left-radius: $border-radius; }
  }

  &:last-child {
    border-bottom: 0;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    &::before { border-bottom-left-radius: $border-radius; }
  }

  &:focus,
  &:hover {
    text-decoration: none;
    background-color: var(--color-state-hover-secondary-bg);
    outline: none;
  }

  &:active {
    background-color: var(--color-bg-secondary);
  }

  &.selected,
  &[aria-selected=true],
  &[aria-current]:not([aria-current=false]) {
    cursor: default;
    background-color: var(--color-menu-bg-active);

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 2px;
      content: "";
      // stylelint-disable-next-line primer/colors
      background-color: var(--color-menu-border-active);
    }
  }

  .octicon {
    width: 16px;
    margin-right: $spacer-2;
    // stylelint-disable-next-line primer/colors
    color: var(--color-icon-tertiary);
    text-align: center;
  }

  .Counter {
    float: right;
    margin-left: $spacer-1;
  }

  .menu-warning {
    float: right;
    // stylelint-disable-next-line primer/colors
    color: var(--color-auto-red-9);
  }

  .avatar {
    float: left;
    margin-right: $spacer-1;
  }

  &.alert {
    .Counter {
      color: var(--color-text-danger);
    }
  }
}

.menu-heading {
  display: block;
  padding: $spacer-2 $spacer-3;
  margin-top: 0;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: $font-weight-bold;
  color: var(--color-menu-heading-text);
  border-bottom: $border-width $border-style var(--color-border-secondary);

  &:hover {
    text-decoration: none;
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &:last-child {
    border-bottom: 0;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}
