// stylelint-disable selector-max-type
.blankslate {
  position: relative;
  padding: $spacer-5;
  text-align: center;

  p {
    color: var(--color-text-secondary);
  }

  code {
    // stylelint-disable-next-line primer/spacing
    padding: 2px 5px 3px;
    font-size: $h5-size;
    background: var(--color-bg-canvas);
    border: $border-width $border-style var(--color-auto-gray-0); // stylelint-disable-line primer/borders
    border-radius: $border-radius;
  }

  img {
    width: 56px;
    height: 56px;
  }
}

.blankslate-icon {
  margin-right: $spacer-1;
  margin-bottom: $spacer-2;
  margin-left: $spacer-1;
  // stylelint-disable-next-line primer/colors
  color: var(--color-blankslate-icon);
}

.blankslate-capped {
  border-radius: 0 0 $border-radius $border-radius;
}

.blankslate-spacious {
  // stylelint-disable-next-line primer/spacing
  padding: ($spacer-6 * 2) $spacer-6;
}

.blankslate-narrow {
  max-width: 485px;
  margin: 0 auto;
}

// was .large-format
// QUESTION: should we deprecate this?
.blankslate-large {
  img {
    width: 80px;
    height: 80px;
  }

  h3 {
    margin: $spacer-3 0;
    //font-size: $h3-size; // This doesn't actually make the text larger. Should this be $h2-size?
    font-size: $h2-size;
  }

  p {
    font-size: $h4-size;
  }
}

// was .clean-background
// TO DO: deprecate this and use utility instead
.blankslate-clean-background {
  border: 0;
}
