// stylelint-disable selector-max-type, no-duplicate-selectors

// Default flash
.flash {
  position: relative;
  // stylelint-disable-next-line primer/spacing
  padding: 20px $spacer-3;
  border-style: $border-style;
  border-width: $border-width;
  border-radius: $border-radius;

  p:last-child {
    margin-bottom: 0;
  }

  .octicon {
    // stylelint-disable-next-line primer/spacing
    margin-right: 12px;
  }
}

// Contain the flash messages
.flash-messages {
  margin-bottom: $spacer-4;
}

// Close button
.flash-close {
  float: right;
  padding: $spacer-3;
  margin: -$spacer-3;
  text-align: center;
  cursor: pointer;

  // Undo `<button>` styles
  background: none;
  border: 0;
  appearance: none;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }

  .octicon {
    margin-right: 0;
  }
}

// Action button
.flash-action {
  float: right;
  // stylelint-disable-next-line primer/spacing
  margin-top: -3px;
  margin-left: $spacer-4;
  background-clip: padding-box;
}

//
// Color variations
//

.flash {
  color: var(--color-alert-info-text);
  background-image: linear-gradient(var(--color-alert-info-bg), var(--color-alert-info-bg));
  border-color: var(--color-alert-info-border);

  .octicon {
    // stylelint-disable-next-line primer/colors
    color: var(--color-alert-info-icon);
  }
}

.flash-warn {
  color: var(--color-alert-warn-text);
  background-image: linear-gradient(var(--color-alert-warn-bg), var(--color-alert-warn-bg));
  border-color: var(--color-alert-warn-border);

  .octicon {
    // stylelint-disable-next-line primer/colors
    color: var(--color-alert-warn-icon);
  }
}

.flash-error {
  color: var(--color-alert-error-text);
  background-image: linear-gradient(var(--color-alert-error-bg), var(--color-alert-error-bg));
  border-color: var(--color-alert-error-border);

  .octicon {
    // stylelint-disable-next-line primer/colors
    color: var(--color-alert-error-icon);
  }
}

.flash-success {
  color: var(--color-alert-success-text);
  background-image: linear-gradient(var(--color-alert-success-bg), var(--color-alert-success-bg));
  border-color: var(--color-alert-success-border);

  .octicon {
    // stylelint-disable-next-line primer/colors
    color: var(--color-alert-success-icon);
  }
}

//
// Layout variations
//

.flash-full {
  // stylelint-disable-next-line primer/spacing
  margin-top: -1px;
  border-width: $border-width 0;
  border-radius: 0;
}

// A banner rendered at the top of the page.
.flash-banner {
  position: fixed;
  top: 0;
  z-index: 90;
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

// Makes sure the background is opaque to cover any content underneath
.flash-full,
.flash-banner {
  background-color: var(--color-bg-canvas);
}

// FIXME deprecate this
.warning {
  padding: $em-spacer-5;
  // stylelint-disable-next-line primer/spacing
  margin-bottom: 0.8em;
  font-weight: $font-weight-bold;
  background-color: var(--color-alert-warn-bg);
}
