// Toast

.Toast {
  display: flex;
  margin: $spacer-2;
  color: var(--color-toast-text);
  background-color: var(--color-toast-bg);
  border-radius: $border-radius;
  box-shadow: inset 0 0 0 1px var(--color-toast-border), var(--color-toast-shadow);

  @include breakpoint(sm) {
    width: max-content;
    max-width: 450px;
    margin: $spacer-3;
  }
}

.Toast-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $spacer-3 * 3;
  flex-shrink: 0;
  color: var(--color-toast-icon); // stylelint-disable-line primer/colors
  background-color: var(--color-toast-icon-bg);
  border: $border-width $border-style var(--color-toast-icon-border);
  border-right: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.Toast-content {
  padding: $spacer-3;
}

.Toast-dismissButton {
  max-height: 54px; // keeps button aligned to the top
  padding: $spacer-3;
  color: inherit;
  background-color: transparent;
  border: 0;

  &:focus,
  &:hover {
    outline: none;
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
}

// Modifier

.Toast--loading {
  color: var(--color-toast-loading-text);
  box-shadow: inset 0 0 0 1px var(--color-toast-loading-border), var(--color-toast-shadow);

  .Toast-icon {
    color: var(--color-toast-loading-icon); // stylelint-disable-line primer/colors
    background-color: var(--color-toast-loading-icon-bg);
    border-color: var(--color-toast-loading-icon-border);
  }
}

.Toast--error {
  color: var(--color-toast-danger-text);
  box-shadow: inset 0 0 0 1px var(--color-toast-danger-border), var(--color-toast-shadow);

  .Toast-icon {
    color: var(--color-toast-danger-icon); // stylelint-disable-line primer/colors
    background-color: var(--color-toast-danger-icon-bg);
    border-color: var(--color-toast-danger-icon-border);
  }
}

.Toast--warning {
  color: var(--color-toast-warning-text);
  box-shadow: inset 0 0 0 1px var(--color-toast-warning-border), var(--color-toast-shadow);

  .Toast-icon {
    color: var(--color-toast-warning-icon); // stylelint-disable-line primer/colors
    background-color: var(--color-toast-warning-icon-bg);
    border-color: var(--color-toast-warning-icon-border);
  }
}

.Toast--success {
  color: var(--color-toast-success-text);
  box-shadow: inset 0 0 0 1px var(--color-toast-success-border), var(--color-toast-shadow);

  .Toast-icon {
    color: var(--color-toast-success-icon); // stylelint-disable-line primer/colors
    background-color: var(--color-toast-success-icon-bg);
    border-color: var(--color-toast-success-icon-border);
  }
}

// Animations

.Toast--animateIn {
  animation: Toast--animateIn 0.18s cubic-bezier(0.22, 0.61, 0.36, 1) backwards;
}

@keyframes Toast--animateIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.Toast--animateOut {
  animation: Toast--animateOut 0.18s cubic-bezier(0.55, 0.06, 0.68, 0.19) forwards;
}

@keyframes Toast--animateOut {
  100% {
    pointer-events: none;
    opacity: 0;
    transform: translateY(100%);
  }
}

.Toast--spinner {
  animation: Toast--spinner 1000ms linear infinite;
}

@keyframes Toast--spinner {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
