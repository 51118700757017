$marketing-font-path: "/fonts/" !default;

$font-mktg: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

// Builds upon @primer/css/support/variables/typography.scss
$h000-size: 64px !default;
$h000-size-mobile: 48px !default;

// Animations
$transition-time: 0.4s !default;
$ease-mktg: cubic-bezier(0.16, 1, 0.3, 1) !default;

// Increases the core spacing scale first by 8px for $spacer-7, then by 16px
// increments from $spacer-8 to $spacer-12, i.e. after 40px, we have 48, 64,
// 80, 96, etc.
$spacer-7:  $spacer * 6 !default;  // 48px
$spacer-8:  $spacer * 8 !default;  // 64px
$spacer-9:  $spacer * 10 !default; // 80px
$spacer-10: $spacer * 12 !default; // 96px
$spacer-11: $spacer * 14 !default; // 112px
$spacer-12: $spacer * 16 !default; // 128px

$marketing-spacers: (
  7: $spacer-7,
  8: $spacer-8,
  9: $spacer-9,
  10: $spacer-10,
  11: $spacer-11,
  12: $spacer-12,
) !default;

$marketing-all-spacers: map-merge(
  (
    0: 0,
    1: $spacer-1,
    2: $spacer-2,
    3: $spacer-3,
    4: $spacer-4,
    5: $spacer-5,
    6: $spacer-6,
  ),
  $marketing-spacers,
) !default;

$marketing-position-variants: (
  "": "",
  md: "-md",
  lg: "-lg",
) !default;
