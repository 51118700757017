// Keyboard shortcuts
// stylelint-disable selector-max-type

kbd {
  display: inline-block;
  // stylelint-disable-next-line primer/spacing
  padding: ($spacer-1 - 1) ($spacer-1 + 1);
  font: 11px $mono-font;
  // stylelint-disable-next-line primer/typography
  line-height: 10px;
  // stylelint-disable-next-line primer/colors
  color: var(--color-auto-gray-7);
  vertical-align: middle;
  background-color: var(--color-bg-secondary);
  // stylelint-disable-next-line primer/borders
  border: $border-style $border-width var(--color-border-tertiary);
  border-bottom-color: var(--color-border-tertiary);
  border-radius: $border-radius;
  // stylelint-disable-next-line primer/box-shadow
  box-shadow: inset 0 -1px 0 var(--color-border-tertiary);
}
