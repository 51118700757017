// Outer wrapper
// stylelint-disable selector-max-type
.tabnav {
  margin-top: 0;
  margin-bottom: $spacer-3;
  border-bottom: $border-width $border-style var(--color-border-primary);
}

.tabnav-tabs {
  display: flex;
  // stylelint-disable-next-line primer/spacing
  margin-bottom: -1px;
  overflow: auto;
}

.tabnav-tab {
  display: inline-block;
  flex-shrink: 0;
  padding: $spacer-2 $spacer-3;
  font-size: $h5-size;
  // stylelint-disable-next-line primer/typography
  line-height: 23px;
  color: var(--color-text-secondary);
  text-decoration: none;
  background-color: transparent;
  border: $border-width $border-style transparent;
  border-bottom: 0;
  transition: color 0.2s cubic-bezier(0.3, 0, 0.5, 1);

  &.selected,
  &[aria-selected=true],
  &[aria-current]:not([aria-current=false]) {
    color: var(--color-text-primary);
    background-color: var(--color-bg-canvas); // cover bottom border
    border-color: var(--color-border-primary);
    border-radius: $border-radius $border-radius 0 0;

    .octicon {
      color: inherit;
    }
  }

  &:hover,
  &:focus {
    color: var(--color-text-primary);
    text-decoration: none;
    transition-duration: 0.1s;
  }

  &:active {
    color: var(--color-text-tertiary);
  }

  .octicon {
    margin-right: $spacer-1;
    // stylelint-disable-next-line primer/colors
    color: var(--color-icon-tertiary);
  }

  .Counter {
    margin-left: $spacer-1;
    color: inherit;
  }
}

// Tabnav extras
//
// Tabnav extras are non-tab elements that sit in the tabnav. Usually they're
// inline text or links.

.tabnav-extra {
  display: inline-block;
  // stylelint-disable-next-line primer/spacing
  padding-top: 10px;
  // stylelint-disable-next-line primer/spacing
  margin-left: 10px;
  font-size: $font-size-small;
  color: var(--color-text-secondary);

  > .octicon {
    // stylelint-disable-next-line primer/spacing
    margin-right: 2px;
  }
}

// When tabnav-extra are anchors
// stylelint-disable-next-line selector-no-qualifying-type
a.tabnav-extra:hover {
  color: var(--color-text-link);
  text-decoration: none;
}

// Tabnav buttons
//
// For when there are multiple buttons, space them out appropriately. Requires
// the buttons to be floated or inline-block.

.tabnav-btn {
  margin-left: $spacer-2;
}
