// Core border utilities
// stylelint-disable block-opening-brace-space-before

/* Add a gray border to the left and right */
.border-x {
  border-right: $border !important;
  border-left: $border !important;
}

/* Add a gray border to the top and bottom */
.border-y {
  border-top: $border !important;
  border-bottom: $border !important;
}

/* Responsive gray borders */
@each $breakpoint, $variant in $responsive-variants {
  @include breakpoint($breakpoint) {
    /* Add a gray border on all sides at/above this breakpoint */
    .border#{$variant}        { border: $border !important; }
    .border#{$variant}-0      { border: 0 !important; }

    .border#{$variant}-top    { border-top: $border !important; }
    .border#{$variant}-right  { border-right: $border !important; }
    .border#{$variant}-bottom { border-bottom: $border !important; }
    .border#{$variant}-left   { border-left: $border !important; }

    .border#{$variant}-top-0    { border-top: 0 !important; }
    .border#{$variant}-right-0  { border-right: 0 !important; }
    .border#{$variant}-bottom-0 { border-bottom: 0 !important; }
    .border#{$variant}-left-0   { border-left: 0 !important; }

    // Rounded corners
    .rounded#{$variant}   { border-radius: $border-radius !important; }
    .rounded#{$variant}-0 { border-radius: 0 !important; }
    .rounded#{$variant}-1 { border-radius: $border-radius-1 !important; }
    .rounded#{$variant}-2 { border-radius: $border-radius-2 !important; }
    .rounded#{$variant}-3 { border-radius: $border-radius-3 !important; }

    @each $edge, $corners in $edges {
      .rounded#{$variant}-#{$edge}-0 {
        @each $corner in $corners {
          border-#{$corner}-radius: 0 !important;
        }
      }

      .rounded#{$variant}-#{$edge}-1 {
        @each $corner in $corners {
          border-#{$corner}-radius: $border-radius-1 !important;
        }
      }

      .rounded#{$variant}-#{$edge}-2 {
        @each $corner in $corners {
          border-#{$corner}-radius: $border-radius-2 !important;
        }
      }

      .rounded#{$variant}-#{$edge}-3 {
        @each $corner in $corners {
          border-#{$corner}-radius: $border-radius-3 !important;
        }
      }
    }
  }
}

/* Add a 50% border-radius to make something into a circle */
.circle { border-radius: 50% !important; }

/* Change the border style to dashed, in conjunction with another utility */
.border-dashed {
  // stylelint-disable-next-line primer/borders
  border-style: dashed !important;
}
