// Circle badge icon with drop shadow for icons and logos

.CircleBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-bg-canvas);
  border-radius: 50%;
  box-shadow: var(--color-shadow-medium);
}

.CircleBadge-icon {
  max-width: 60% !important;
  height: auto !important;
  max-height: 55% !important;
}

// Small badge
.CircleBadge--small {
  width: 56px;
  height: 56px;
}

// Medium badge
.CircleBadge--medium {
  width: 96px;
  height: 96px;
}

// Large badge
.CircleBadge--large {
  width: 128px;
  height: 128px;
}

// Dashed line that connects badges..
// Wrap around 2 or more badges to create a horizonal line:

.DashedConnection {
  position: relative;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    content: "";
    // stylelint-disable-next-line primer/borders
    border-bottom: 2px dashed var(--color-border-primary);
  }

  .CircleBadge {
    position: relative;
  }
}
