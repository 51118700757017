// stylelint-disable selector-max-type, selector-no-qualifying-type

// A nice way to display branch names inside the UI. Can be a link or not.

.branch-name {
  display: inline-block;
  // stylelint-disable-next-line primer/spacing
  padding: 2px 6px;
  font: 12px $mono-font;
  color: var(--color-branch-name-text);
  background-color: var(--color-branch-name-bg);
  border-radius: $border-radius;

  .octicon {
    // stylelint-disable-next-line primer/spacing
    margin: 1px -2px 0 0;
    // stylelint-disable-next-line primer/colors
    color: var(--color-branch-name-icon);
  }
}

// When a branch name is a link

a.branch-name {
  color: var(--color-text-link);
  background-color: var(--color-branch-name-link-bg);

  .octicon {
    // stylelint-disable-next-line primer/colors
    color: var(--color-branch-name-link-icon);
  }
}
