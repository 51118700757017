// A pop up list of items used to show autocompleted results
.autocomplete-results {
  position: absolute;
  z-index: 99;
  width: 100%;
  max-height: 20em;
  overflow-y: auto;
  // stylelint-disable-next-line primer/typography
  font-size: 13px;
  list-style: none;
  background: var(--color-bg-overlay);
  border: $border-width $border-style var(--color-border-overlay);
  border-radius: $border-radius;
  box-shadow: var(--color-autocomplete-shadow);
}

// One of the items that appears within an autocomplete group
// Bold black text on white background

.autocomplete-item {
  display: block;
  width: 100%;
  padding: $spacer-1 $spacer-2;
  overflow: hidden;
  font-weight: $font-weight-bold;
  color: var(--color-text-primary);
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-color: var(--color-bg-overlay);
  border: 0;

  &:hover {
    color: var(--color-state-hover-primary-text);
    text-decoration: none;
    background-color: var(--color-state-hover-primary-bg);

    // Inherit color on all child elements to ensure enough contrast
    * {
      color: inherit !important;
    }
  }

  &.selected,
  &[aria-selected=true],
  &.navigation-focus {
    color: var(--color-state-selected-primary-text);
    text-decoration: none;
    background-color: var(--color-state-selected-primary-bg);

    // Inherit color on all child elements to ensure enough contrast
    * {
      color: inherit !important;
    }
  }
}
