// stylelint-disable block-opening-brace-space-before

// Text colors
.color-text-primary   { color: var(--color-text-primary) !important; }
.color-text-secondary { color: var(--color-text-secondary) !important; }
.color-text-tertiary  { color: var(--color-text-tertiary) !important; }
.color-text-link      { color: var(--color-text-link) !important; }
.color-text-success   { color: var(--color-text-success) !important; }
.color-text-warning   { color: var(--color-text-warning) !important; }
.color-text-danger    { color: var(--color-text-danger) !important; }
.color-text-inverse   { color: var(--color-text-inverse) !important; }
.color-text-white     { color: var(--color-text-white) !important; }

// Icon colors
.color-icon-primary   { color: var(--color-icon-primary) !important; } // stylelint-disable-line primer/colors
.color-icon-secondary { color: var(--color-icon-secondary) !important; } // stylelint-disable-line primer/colors
.color-icon-tertiary  { color: var(--color-icon-tertiary) !important; } // stylelint-disable-line primer/colors
.color-icon-info      { color: var(--color-icon-info) !important; } // stylelint-disable-line primer/colors
.color-icon-danger    { color: var(--color-icon-danger) !important; } // stylelint-disable-line primer/colors
.color-icon-success   { color: var(--color-icon-success) !important; } // stylelint-disable-line primer/colors
.color-icon-warning   { color: var(--color-icon-warning) !important; } // stylelint-disable-line primer/colors

// Border colors
.color-border-primary   { border-color: var(--color-border-primary) !important; }
.color-border-secondary { border-color: var(--color-border-secondary) !important; }
.color-border-tertiary  { border-color: var(--color-border-tertiary) !important; }
.color-border-overlay   { border-color: var(--color-border-overlay) !important; }
.color-border-inverse   { border-color: var(--color-border-inverse) !important; }
.color-border-info      { border-color: var(--color-border-info) !important; }
.color-border-success   { border-color: var(--color-border-success) !important; }
.color-border-danger    { border-color: var(--color-border-danger) !important; }
.color-border-warning   { border-color: var(--color-border-warning) !important; }

// Background colors
.color-bg-canvas          { background-color: var(--color-bg-canvas) !important; }
.color-bg-canvas-inverse  { background-color: var(--color-bg-canvas-inverse) !important; }
.color-bg-canvas-inset    { background-color: var(--color-bg-canvas-inset) !important; }
.color-bg-primary         { background-color: var(--color-bg-primary) !important; }
.color-bg-secondary       { background-color: var(--color-bg-secondary) !important; }
.color-bg-tertiary        { background-color: var(--color-bg-tertiary) !important; }
.color-bg-overlay         { background-color: var(--color-bg-overlay) !important; }
.color-bg-info            { background-color: var(--color-bg-info) !important; }
.color-bg-info-inverse    { background-color: var(--color-bg-info-inverse) !important; }
.color-bg-danger          { background-color: var(--color-bg-danger) !important; }
.color-bg-danger-inverse  { background-color: var(--color-bg-danger-inverse) !important; }
.color-bg-success         { background-color: var(--color-bg-success) !important; }
.color-bg-success-inverse { background-color: var(--color-bg-success-inverse) !important; }
.color-bg-warning         { background-color: var(--color-bg-warning) !important; }
.color-bg-warning-inverse { background-color: var(--color-bg-warning-inverse) !important; }

// Inherit
.text-inherit { color: inherit !important; }
