// Box shadow utilities

// Box shadows

.color-shadow-small {
  box-shadow: var(--color-shadow-small) !important;
}

.color-shadow-medium {
  box-shadow: var(--color-shadow-medium) !important;
}

.color-shadow-large {
  box-shadow: var(--color-shadow-large) !important;
}

.color-shadow-extra-large {
  box-shadow: var(--color-shadow-extra-large) !important;
}

// Turn off box shadow

.box-shadow-none {
  box-shadow: none !important;
}
