// .avatar-parent-child is when you see a small avatar at the bottom right
// corner of a larger avatar.
//
// No Styleguide version
.avatar-parent-child {
  position: relative;
}

.avatar-child {
  position: absolute;
  right: -15%;
  bottom: -9%;
  background-color: var(--color-bg-canvas); // For transparent backgrounds
  // stylelint-disable-next-line primer/borders
  border-radius: $border-radius-1;
  box-shadow: var(--color-avatar-child-shadow);
}
