// Links

.Link {
  color: var(--color-text-link);

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.Link--primary {
  color: var(--color-text-primary) !important;

  &:hover {
    color: var(--color-text-link) !important;
  }
}

.Link--secondary {
  color: var(--color-text-secondary) !important;

  &:hover {
    color: var(--color-text-link) !important;
  }
}

.Link--muted {
  color: var(--color-text-secondary) !important;

  &:hover {
    color: var(--color-text-link) !important;
    text-decoration: none;
  }
}

// Set the link color only on hover
// Useful when you want only part of a link to turn blue on hover
.Link--onHover {
  &:hover {
    color: var(--color-text-link) !important;
    text-decoration: underline;
    cursor: pointer;
  }
}

// When using a color utility class inside of a link class,
// color should change with link on hover.
.Link--secondary,
.Link--primary,
.Link--muted {
  &:hover [class*="color-text"] {
    color: inherit !important;
  }
}
